<template>
    <div class="lunbo">
        <el-form label-width="80px">
            <el-form-item v-if="image != ''" label="公司照片">
                <el-image style="height:150px;width:150px" :src="image | getImageUrl(CDN)" fit="contain"></el-image>
            </el-form-item>
            <el-form-item label="公司名称">
                <div class="info">{{ company }}</div>
            </el-form-item>
            <el-form-item label="公司简介">
                <div class="info">{{ introduce }}</div>
            </el-form-item>
            <el-form-item label="联系地址">
                <div class="info">{{ address }}</div>
            </el-form-item>
            <el-form-item label="联系电话">
                <div class="info">{{ mobile }}</div>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="edit">修改内容</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import imageUploader from "@/components/imageUploader";
export default {
    components: { imageUploader },
    data() {
        return {
            company: "",
            introduce: "",
            address: "",
            image: "",
            mobile: "",
            CDN: this.$cdn(),
        };
    },
    filters: {
        getImageUrl: function(src, host) {
            return host + src;
        },
    },
    mounted() {
        this.loadContact();
    },
    methods: {
        // 后退
        goBack: function() {
            this.$router.go(-1);
        },
        uploadSucess: function(val) {
            this.mainForm.image = val;
        },
        loadContact: function() {
            this.$http({
                url: "/sys/operation/getContact",
                data: {},
            }).then((res) => {
                (this.company = res.data.company), (this.introduce = res.data.introduce), (this.address = res.data.address), (this.image = res.data.image), (this.mobile = res.data.mobile);
            });
        },
        edit: function() {
            this.$router.push("/operate/contact-edit");
        },
    },
};
</script>
<style>
.info {
    font-size: 15px;
}
</style>
